import React from "react";
import { Link } from "gatsby";
import styled from "styled-components";

const ButtonStyles = styled.div`
  font-size: 16px;
  min-width: 50px;
  padding: 10px 34px;
  background: var(--husblack);
  color: var(--huswhite);
  display: inline-block;
  border-radius: 25px;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  transition: all 0.1s ease-in;
  font-weight: 600;

  &:hover {
    background: #525151;
  }
`;

export default function Button({ linktext, linkto }) {
  return (
    <>
      <a className="button" href={linkto}>
        <ButtonStyles>{linktext}</ButtonStyles>
      </a>
    </>
  );
}
