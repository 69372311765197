import { createGlobalStyle } from "styled-components";
import recolettasemiboldwoff from "../assets/fonts/Recoleta-SemiBold.woff";
import recolettasemiboldwoff2 from "../assets/fonts/Recoleta-SemiBold.woff2";
import recolettaboldwoff from "../assets/fonts/Recoleta-Bold.woff";
import recolettaboldwoff2 from "../assets/fonts/Recoleta-Bold.woff2";
import "@fontsource/lato"; // Defaults to weight 400.
import "@fontsource/lato/300.css";
import "@fontsource/lato/700.css";
import "@fontsource/lato/900.css";
const Typography = createGlobalStyle`
  @font-face {
  font-family: husmates-semibold;
   src: url(${recolettasemiboldwoff})
}

@font-face {
  font-family: husmatesbold;
   src: url(${recolettaboldwoff}) format('woff')
}

html {
    font-family: 'Lato';
    
    color: var(--black);
    font-size:14px;
  }

  p, li {
    letter-spacing: 0.5px;
    line-height: 1.5;
    &.bold{
      {
  font-weight:600;
 }
    }

  }

  h1,h2,h3,h4,h5,h6 {
    font-family: husmates-semibold, sans-serif;
   &.semi{
    font-family: husmates-semibold, sans-serif;
   }
   &.bold{
    font-family: husmatesbold, sans-serif;
   }
  }



  a {
    color: var(--black);
    text-decoration:underline;
    /* Chrome renders this weird with this font, so we turn it off */
    text-decoration-skip-ink: none;
  }

  a.button {
    color: var(--black);
    text-decoration:none;
    /* Chrome renders this weird with this font, so we turn it off */
    text-decoration-skip-ink: none;
  }

`;

export default Typography;
