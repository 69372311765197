import React, { useEffect } from "react";
import Header from "../components/header/Header";
import "normalize.css";
import Footer from "./Footer/Footer";
import Typography from "../styles/Typography";
import { useIntercom } from "react-use-intercom";
import { isDesktop } from "react-device-detect";
export default function Layout({ children }) {
  const { boot, shutdown, hide, show, update } = useIntercom();
  useEffect(() => {
    boot(); // Initializes Intercom
    if (isDesktop) {
      show(); // Opens the Intercom widget
    }
  }, []);
  return (
    <>
      {" "}
      <div className="cutoff">
        <Typography />
        <Header />
        {children}

        <div className="wrapper-full">
          <Footer />
        </div>
      </div>
    </>
  );
}
