import React from "react";
import { Link } from "gatsby";

import styled from "styled-components";
import Logo from "../Logo/Logo";
import Button from "../button/Button";
import Menu from "../Menu/Menu";
import MobileMenu from "../Menu/Menu";
const HeaderStyles = styled.div`
  padding: 35px 0px;
  //position: fixed;
  //width:100%;
  z-index: 1;
`;

const HeaderInner = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;

  .right {
    display: flex;
    align-items: flex-end;
    ul {
      display: flex;
      align-items: center;
      justify-content: flex-end;
      li {
        &:last-child {
          margin-right: 0;
        }
        &.link {
          margin-left: 35px;
          font-size: 1.2em;

          a {
            font-weight: 700;
            &.active {
              text-decoration: underline;
            }
            &:hover {
              text-decoration: underline;
            }
          }
        }
        &.hide-mobile {
          display: none;
          @media (min-width: 450px) {
            display: none;
          }
        }
        &.hide-tablet {
          display: none;
          @media (min-width: 960px) {
            display: block;
          }
        }
        display: inline;
        margin-right: 15px;
        &:last-child {
          margin-right: 0;
        }
      }
    }
  }
`;

export default function Header() {
  return (
    <>
      <HeaderStyles>
        <HeaderInner className="wrapper-full ">
          <div className="left">
            {" "}
            <Logo />
          </div>
          <div className="right">
            <div className="menu">
              <ul>
                <li className="link hide-tablet">
                  <Link activeClassName="active" to="/about">
                    About
                  </Link>
                </li>
                <li className="link hide-tablet">
                  <Link activeClassName="active" to="/blog">
                    Blog
                  </Link>
                </li>
                <li className="link hide-tablet">
                  <Link activeClassName="active" to="/co-ownership">
                    Co-Ownership
                  </Link>
                </li>
                <li className="link hide-tablet">
                  <Link activeClassName="active" to="/our-team">
                    Our Team
                  </Link>
                </li>
                <li className="link hide-tablet">
                  <Link activeClassName="active" to="/how-it-works">
                    How it Works
                  </Link>
                </li>

                {/* <li className="hide-tablet">
                  <Button
                    linkto="https://beta.husmates.com/signup"
                    linktext="Sign Up"
                  />
                </li> */}
                {/* <li className="hide-tablet">
                  <Button linkto="/beta" linktext="Log in" />
                </li>
                <li className="hide-tablet">
                  <Button linkto="/beta" linktext="Sign up" />
                </li> */}

                {/* <li>
                  <Button
                    linkto="https://beta.husmates.com/login"
                    linktext="Get Started"
                  />
                </li> */}
                <li>
                  <MobileMenu />
                </li>
              </ul>
            </div>
          </div>
        </HeaderInner>
      </HeaderStyles>
    </>
  );
}
