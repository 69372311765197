import React from "react";
import { Link } from "gatsby";
import styled from "styled-components";
import Logoimage from "../../assets/images/logo.svg";

const LogoStyles = styled.div`
  grid-column-start: 1;
  grid-column-end: 4;
`;

const LogoImageStyles = styled.img`
  width: 110px;
`;

export default function Logo() {
  return (
    <>
      <LogoStyles>
        <Link to="/">
          <LogoImageStyles src={Logoimage}></LogoImageStyles>
        </Link>
      </LogoStyles>
    </>
  );
}
