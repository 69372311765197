import { createGlobalStyle } from "styled-components";

const GlobalStyles = createGlobalStyle`
  :root {
    --red: #FF4949;
    --black: #2E2E2E;
    --yellow: #ffc600;
    --white: #fff;
    --grey: #efefef;
    --huswhite: #ffffff;
    --huspink: #FEEFE2;
    --huslightpink: #f8ebe0;
    --husdarkpink: #EDD8C6;
    --husblack:#333333;
  }
  html {
    background: var(--huspink);
    overflow-x: hidden;
   
  }

  body {
    font-size: 1.1rem;
    font-family:'Lato', sans-serif;
    -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  line-height:1.3;
  position:relative;
  /* opacity:1; */
  /* transition: opacity .3s ease-in; */
/* width:100vw; */
/* overflow-x:hidden; */
  &.no-js {
  /* opacity:0; */
  }

  .cutoff {
    position:absolute;
    top:0;
    bottom:0;
    left:0;
    right:0;
    /* overflow-x:hidden; */
    max-width:100vw;
   
  }
  }

  a { text-decoration:none}
  fieldset {
    border-color: rgba(0,0,0,0.1);
    border-width: 1px;
  }
  button {
   
  }

  textarea:focus, input:focus{
    outline: none;
}
  hr {
    border: 0;
    height: 8px;
  
    background-size: 1500px;
  }
  img {
    max-width: 100%;
  }
  
  figure{
    margin:0;
    margin-top: 1.5rem;
    margin-bottom: 1.5rem;
    padding:0;
  }
  .wrapper-wide {
      max-width: 1364px;
      margin-left: auto;
      margin-right: auto;
      padding-left: 16px;
     padding-right: 16px;
  }
  .wrapper-full {
      max-width: 1280px;
      margin-left: auto;
      margin-right: auto;
      padding-left: 16px;
     padding-right: 16px;
  }

  .grid16{
      display: grid;
      grid-template-columns: repeat(16, [col-start] 1fr);
  }
`;

export default GlobalStyles;
