import React, { useState } from "react";
import { Link } from "gatsby";
import styled from "styled-components";
import bg from "../../assets/images/bg/shape1svg.svg";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { library } from "@fortawesome/fontawesome-svg-core";
import { fab } from "@fortawesome/free-brands-svg-icons";
import { fas } from "@fortawesome/free-solid-svg-icons";
import { StyledOffCanvas, Menu, Overlay } from "styled-off-canvas";
import { faBars } from "@fortawesome/free-solid-svg-icons";
import Button from "../button/Button";
import apple from "../../assets/images/home/apple.svg";
import google from "../../assets/images/home/google.svg";
const OpenButton = styled.div`
  font-size: 16px;

  padding: 10px 15px;
  background: white;
  color: var(--husBlack);
  display: inline-block;
  border-radius: 25px;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  transition: all 0.1s ease-in;
  font-weight: 600;
  display: block;
  @media (min-width: 960px) {
    display: none;
  }
`;

const MobileMenuDiv = styled.div`
  font-size: 1.4em;

  margin-top: 50px;
  padding: 10px 25px;

  color: var(--husBlack);
  display: inline-block;
  border-radius: 25px;
  display: block;

  justify-content: center;
  transition: all 0.1s ease-in;
  font-weight: 600;

  .mobileLink {
    margin-bottom: 10px;
    a.button {
      margin-top: 30px;
      margin-right: 15px;
    }
  }
  .mobileLinkButton {
    margin-top: 30px;
    a.button {
      margin-top: 30px;
      margin-right: 15px;
    }
  }
`;

const CloseButton = styled.a`
  position: absolute;
  top: 20px;
  right: 20px;
  font-size: 12px;
  font-weight: 900;
  color: var(--black);
  padding: 10px 15px;

  display: inline-block;
  border-radius: 25px;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  transition: all 0.1s ease-in;

  display: block;
`;

const Img = styled.div`
  position: absolute;
  /* top: 0; */
  height: 100vh;
  width: 500px;
  top: 50%;
  transform: translate(-20%, -50%);
  z-index: -1;
`;

export default function MobileMenu() {
  const [isOpen, setIsOpen] = useState(false);

  return (
    <StyledOffCanvas
      width="350px"
      isOpen={isOpen}
      onClose={() => setIsOpen(false)}
    >
      <OpenButton onClick={() => setIsOpen(!isOpen)}>
        <FontAwesomeIcon className="fa-lg" icon={faBars} />
      </OpenButton>

      <Menu css={{ overflow: "hidden" }}>
        <CloseButton onClick={() => setIsOpen(false)}>CLOSE</CloseButton>

        <MobileMenuDiv>
          <div className="mobileLink">
            <Link
              activeClassName="active"
              to="/about"
              onClick={() => setIsOpen(false)}
            >
              About
            </Link>
          </div>
          <div className="mobileLink">
            <Link
              activeClassName="active"
              to="/blog"
              onClick={() => setIsOpen(false)}
            >
              Blog
            </Link>
          </div>
          <div className="mobileLink">
            <Link
              activeClassName="active"
              to="/how-it-works"
              onClick={() => setIsOpen(false)}
            >
              How It Works
            </Link>
          </div>
          <div className="mobileLink">
            <Link
              activeClassName="active"
              to="/co-ownership"
              onClick={() => setIsOpen(false)}
            >
              What Is Co-Ownership?
            </Link>
          </div>
          <div className="mobileLink">
            <Link
              activeClassName="active"
              to="https://husmates.com/our-team"
              onClick={() => setIsOpen(false)}
            >
              Our Team
            </Link>
          </div>
          {/* <div className="mobileLinkButton">
            <Button
              linkto="https://beta.husmates.com/signup"
              linktext="Sign Up"
            />
            <Button
              linkto="https://beta.husmates.com/login"
              linktext="Log In"
            />
          </div> */}
          <div className="mobileLinkButton">
            <div className="buttons">
              <a href="https://apps.apple.com/app/husmates/id6446372016">
                <img src={apple} />{" "}
              </a>

              <a href=" https://play.google.com/store/apps/details?id=com.husmates">
                <img src={google} />{" "}
              </a>
            </div>
          </div>
        </MobileMenuDiv>
        <Img>
          <img src={bg} alt="" />
        </Img>
      </Menu>

      <Overlay />
    </StyledOffCanvas>
  );
}
