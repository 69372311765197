import React from "react";
import Layout from "./src/components/Layout";
import SEO from "./src/components/SEO";
import GlobalStyles from "./src/styles/GlobalStyles";
import Tailwind from "./src/styles/tailwind.css";
import { IntercomProvider } from "react-use-intercom";
import { isDesktop } from "react-device-detect";
const INTERCOM_APP_ID = "z4tzeoa4";

export function onClientEntry() {
  window.addEventListener("load", () => {
    document.body.className = document.body.className.replace(/\bno-js\b/, "");
  });
}

export function wrapPageElement({ element, props }) {
  return (
    <>
      {/* <SEO /> */}
      <GlobalStyles />

      <IntercomProvider autoBoot autoBootProps appId={INTERCOM_APP_ID}>
        <Layout {...props}>{element}</Layout>
      </IntercomProvider>
    </>
  );
}
