import React from "react";
import { Link } from "gatsby";
import styled from "styled-components";
import Mailchimp from "react-mailchimp-form";

const NewsletterStyles = styled.div`
  text-align: center;
  max-width: none;
  @media (min-width: 450px) {
    max-width: 400px;
  }
  button,
  input[type="submit"],
  input[type="reset"] {
    background: none;
    color: inherit;
    border: none;
    padding: 0;
    font: inherit;
    cursor: pointer;
    outline: inherit;
  }
  input {
    padding: 10px 20px;
    border: none;

    width: 250px;
    margin-bottom: 10px;
    /* width: 200px; */
    box-sizing: border-box;
    text-align: center;

    border-radius: 30px;
    width: 100%;
    max-width: 350px;
    @media (min-width: 450px) {
      width: 250px;
      text-align: center;
    }
  }
  button {
    padding: 10px 20px;
    background: var(--husblack);
    color: var(--huswhite);
    font-size: 15px;
    font-weight: 600;
    margin-left: 0;
    width: 100%;
    max-width: 350px;
    @media (min-width: 450px) {
      margin-left: 15px;
      width: 130px;
    }

    border-radius: 30px;
    transition: all 0.1s ease-in;
    &:hover {
      background: #525151;
    }
  }
  @media (min-width: 768px) {
    text-align: left;
  }
  .title {
  }
  .text {
  }
  .msg-alert {
    p {
      color: var(--beige) !important;
    }
  }
`;

export default function Newsletter() {
  return (
    <>
      <NewsletterStyles>
        <Mailchimp
          action="https://husmates.us20.list-manage.com/subscribe/post?u=4da13d3d386ce89efa52086fa&amp;id=2d5f8e9c72&amp;f_id=00d5d0edf0"
          fields={[
            {
              name: "EMAIL",
              placeholder: "Your email address",
              type: "email",
              required: true,
            },
          ]}
          messages={{
            sending: "Sending...",
            success: "Thank you for subscribing!",
            error: "An unexpected internal error has occurred.",
            empty: "Please enter an e-mail address.",
            duplicate: "E-mail already subscribed.",
            button: "Subscribe",
          }}
          className="mailchimpform"
        />
      </NewsletterStyles>
    </>
  );
}
